<template>
  <div>
    <validation-observer ref="VFormTransfusi">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <div class="bg_card_blue p-3">
              <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Reg</h4>
                        <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{isParent.row.ap_code||"-"}}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{isParent.row.ap_fullname||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Tempat, Tanggal Lahir </h4>
                        <p>{{isParent.row.ap_pob}}, {{isParent.row.ap_dob | moment('DD MMMM YYYY')}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Golongan Darah</h4>
                        <p>{{isParent.rowEdit.arantl_tr_golongan_darah || "-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jenis Darah</h4>
                        <p>{{isParent.rowEdit.arantl_tr_jenis_darah || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-12">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
                <li class="nav-item" v-for="(v,k) in (dataTransfusi||[])" :key="k">
                <a href="javascript:;" @click="changeTab(k)" :class="activeTab == (k) ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kantong ke #{{k+1}} - ({{v.arantlt_no_kantong}})</a>
                
                </li>
            </ul>
          </div>

          <div class="col-md-12 mt-2">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Serah Terima Darah</h6>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Petugas Bank Darah</label>
                      <b-form-input v-model="rowTransfusi.arantlt_petugas" type="text" class="form-control" placeholder="Petugas Bank Darah" 
                      />
                      <VValidate 
                        :name="'Petugas Bank Darah'" 
                        v-model="rowTransfusi.arantlt_petugas" 
                        :rules="{required: 1, min:2, max:128}"
                      />
                      
                      <div class="signing-element mt-1">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPetugas"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPetugas','arantlt_petugas_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPetugas','arantlt_petugas_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                        
                        <VValidate name="Tanda Tangan Pengirim" v-model="rowTransfusi.arantlt_petugas_ttd"
                        :rules="{required:1}" />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Penerima Darah</label>
                      <b-form-input v-model="rowTransfusi.arantlt_penerima" type="text" class="form-control" placeholder="Penerima Darah" 
                      />
                      <VValidate 
                        :name="'Penerima Darah'" 
                        v-model="rowTransfusi.arantlt_penerima" 
                        :rules="{required: 1, min:2, max:128}"
                      />
                      
                      <div class="signing-element mt-1">
                        <VueSignaturePad
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttdPenerima"
                        />
                        <div class="mt-2">
                        <button type="button" @click="undoTTD('ttdPenerima','arantlt_penerima_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                        </button>
                        
                        <button type="button" @click="output('ttdPenerima','arantlt_penerima_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                            <b><i class="icon-checkmark2"></i></b>
                        <span>Simpan</span>
                        </button>
                        </div>
                        
                        <VValidate name="Tanda Tangan Penerima" v-model="rowTransfusi.arantlt_penerima_ttd"
                        :rules="{required:1}" />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Tanggal Transfuse<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
                        <datepicker input-class="form-control transparent"
                        placeholder="Pilih Tanggal" class="my-datepicker"
                        calendar-class="my-datepicker_calendar" v-model="rowTransfusi.arantlt_date">
                        </datepicker>
                      </div>
                      
                      <VValidate name="Tanggal Transfuse" v-model="rowTransfusi.arantlt_date"
                      :rules="{required:1}" />
                    </div>
                    
                    <div class="form-group">
                      <b-form-checkbox value="Y" unchecked-value="N" v-model="rowTransfusi.arantlt_is_hentikan" class="form-check-input-styled" name="radio-inline-left">
                      Hentikan Transfusi
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-group" v-if="rowTransfusi.arantlt_is_hentikan == 'Y'">
                      <label for="">Keterangan</label>
                      <textarea  v-model="rowTransfusi.arantlt_keterangan" class="form-control" rows="3"></textarea>
                    </div>

                   
                    
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="">Waktu Serah Terima<strong class="text-danger">*</strong></label>
                      <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowTransfusi.arantlt_jam">
                        </vue-timepicker>
                        <VValidate name="Waktu Serah Terima" v-model="rowTransfusi.arantlt_jam"
                        :rules="{required:1}" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="">Upload Nomor Kantong</label>
                      <Uploader v-model="rowTransfusi.arantlt_file" isDocument type="docimage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <table class="table table-bordered table-sm patient-table">
              <div class="display-c" v-for="(v,k) in (dataMonitoring||[])" :key="k">
              <thead >
                <tr>
                  <th colspan="2">Monitoring Kantong ke-{{(k+1)}}</th>
                </tr>
              </thead>
              <tbody v-for="(v1,k1) in (v.data||[])" :key="k1+'ch'">
                <tr v-for="(v2,k2) in (v1.data||[])" :key="k2+'tf'">
                  <td :class="v1.color">{{v1.text}}</td>
                  <td>
                    <div class="row">
                      <div class="form-group col-md-3" style="width:20%;">
                        <label>Tanggal</label>
                        <div class="input-group bootstrap-timepicker timepicker">
                          <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                          </span>
                          <datepicker input-class="form-control transparent"
                          placeholder="Pilih Tanggal" class="my-datepicker"
                          calendar-class="my-datepicker_calendar" v-model="v2.arantm_date">
                          </datepicker>
                        </div>
                        <VValidate :name="'Tanggal'+v2.arantm_id" message="Tanggal Harus Diisi" v-model="v2.arantm_date"
                        :rules="{required:1}" />
                      </div>
                      <div class="form-group col-md-3" style="width:20%;">
                        <label>Jam</label>
                        <div class="input-group bootstrap-timepicker timepicker">
                          <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text"><i class="icon-alarm"></i></span>
                          </span>
                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v2.arantm_waktu">
                          </vue-timepicker>
                        </div>
                        <VValidate :name="'Jam'+v2.arantm_id" message="Jam Harus Diisi" v-model="v2.arantm_waktu"
                        :rules="{required:1}" />
                      </div>
                    </div>
                    

                    <div class="card mb-0">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Tekanan Darah</label>
                              <div class="input-group">
                                <b-form-input :formatter="isParent.number" v-model="v2.arantm_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                                <b-form-input :formatter="isParent.number" placeholder="Diastole" v-model="v2.arantm_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                              </div>
                              <!--
                              <VValidate 
                                :name="'Tekanan Darah Min'+v2.arantm_id"
                                :message="'Tekanan Darah Min Harus Diisi'" 
                                v-model="v2.arantm_ttv_tekanan_darah_min" 
                                :rules="{required: 1, min_value: 0}"
                              />
                              <VValidate 
                                :name="'Tekanan Darah Max'+v2.arantm_id"
                                :message="'Tekanan Darah Max Harus Diisi'" 
                                v-model="v2.arantm_ttv_tekanan_darah_max" 
                                :rules="{required: 1, min_value: 0}"
                              />
                              -->
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Nadi</label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                      <b-form-input :formatter="isParent.number" v-model="v2.arantm_ttv_nadi" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                      <div class="input-group-append">
                                          <div style="width: 140px;">
                                          <v-select placeholder="Pilih Label" v-model="v2.arantm_ttv_label" :options="isParent.Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <!--
                                <VValidate 
                                    :name="'Nadi'+v2.arantm_id" 
                                    :message="'Nadi Harus Diisi'" 
                                    v-model="v2.arantm_ttv_nadi" 
                                    :rules="{required: 1, min_value: 0}"
                                />
                                <VValidate 
                                    :name="'Label'+v2.arantm_id" 
                                    :message="'Label Harus Diisi'"
                                    v-model="v2.arantm_ttv_label" 
                                    :rules="{required: 1}"
                                />
                                -->
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Suhu</label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="isParent.number" v-model="v2.arantm_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--
                              <VValidate 
                                :name="'Suhu'+v2.arantm_id" 
                                :message="'Suhu Harus Diisi'"
                                v-model="v2.arantm_ttv_suhu" 
                                :rules="{required: 1}"
                              />
                              -->
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Pernafasan</label>
                              <div class="input-group">
                                <b-form-input :formatter="isParent.number" v-model="v2.arantm_ttv_pernafasan"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                              </div>
                              <!--
                              <VValidate 
                                :name="'Pernafasan'+v2.arantm_id" 
                                :message="'Pernafasan Harus Diisi'"
                                v-model="v2.arantm_ttv_pernafasan" 
                                :rules="{required: 1}"
                              />
                              -->
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Volume Urine</label>
                              <div class="input-group">
                                <b-form-input :formatter="isParent.number" v-model="v2.arantm_ttv_volume_urine"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">CC</span></div>
                              </div>

                              <!--
                              <VValidate 
                                :name="'Volume Urine'+v2.arantm_id" 
                                :message="'Volume Urine Harus Diisi'"
                                v-model="v2.arantm_ttv_volume_urine" 
                                :rules="{required: 1}"
                              />
                              -->
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Warna Urine</label>
                              <div class="input-group">                              
                                <b-form-input v-model="v2.arantm_ttv_warna_urine"  type="text" class="form-control" />       
                              </div>

                              <!--
                              <VValidate 
                                :name="'Warna Urine'+v2.arantm_id" 
                                :message="'Warna Urine Harus Diisi'"
                                v-model="v2.arantm_ttv_warna_urine" 
                                :rules="{required: 1}"
                              />
                              -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mt-2">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Gejala &amp; Tanda Reaksi</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <b-form-checkbox-group
                              class="checkbox-inline"
                              v-model="v2.arantm_gejala"
                              @change="changeGejala($event,k,k1,k2)"
                              :options="isParent.Config.mr.mGejalaReaksi"
                              name="gejala_reaksi"
                          ></b-form-checkbox-group>
                        </div>
                      </div>
                    </div>

                    <div class="card mt-2" v-if="(v2.arantm_gejala||[]).length">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Reaksi Transfusi {{v2.arantm_reaksi_type}}</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <b-form-checkbox-group
                              class="checkbox-inline"
                              v-model="v2.arantm_reaksi_transfusi"
                              :options="
                              v2.arantm_reaksi_type == 'Ringan' ? isParent.Config.mr.reaksiTransfusiRingan : 
                              v2.arantm_reaksi_type == 'Sedang' ?
                              isParent.Config.mr.reaksiTransfusiSedang : isParent.Config.mr.reaksiTransfusiBerat
                              "
                              name="reaksi_transfusi"
                          ></b-form-checkbox-group>
                        </div>
                      </div>
                    </div>

                    <!--
                    <table class="table  table-sm  table-bordered">
                      <thead>
                        <tr>
                          <th>Reaksi Transfusi Ringan</th>
                          <th>Reaksi Transfusi Sedang</th>
                          <th>Reaksi Transfusi Berat</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>
                            <ul>
                              <li>Hentikan transfuse / lambatkan tetesan transfuse darah </li>
                              <li>Laporkan hasil transfuse ke DPJP dan BDRS </li>
                              <li>Recheck barcode dengan identitas pasien </li>
                              <li>Laksanakan Advis dari DPJP</li>
                            </ul>
                          </th>
                          <th>
                            <ul>
                              <li>Hentikan transfuse darah </li>
                              <li>Ganti transfuse set dengan Infus line NaCl 0,9 %</li>
                              <li>Lapor reaksi transfuse ke DPJP Dan BDRS </li>
                              <li>Kirim daran beserta transfuse setnya ke BDRS </li>
                              <li>Laksanakan Advis dari DPJP </li>
                            </ul>
                          </th>
                          <th>
                            <ul>
                              <li>Hentikan transfuse darah </li>
                              <li>Gabti tranfusi set dengan Infus line NaCl 0,9%</li>
                              <li>Pertahankan patensi jalan nafas </li>
                              <li>Lapor reaksi transfuse ke DPJP dan BDRS </li>
                              <li>Kirim darah beserta transfuse setnya ke BDRS</li>
                            </ul>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    -->

                    <div class="mt-2" v-if="v1.type == 'I'">
                      <!--
                      <template v-if="v2.arantm_is_stop_transfusi != 'Y'">
                        <a @click="v2.arantm_is_stop_transfusi = 'Y'" href="javascript:;" class="btn btn-labeled btn-labeled-left btn-danger">
                          <b><i class="fa-solid fa-hand"></i></b>
                          <span>Hentikan Transfusi</span>
                        </a>
                      </template>
                      <template v-else>
                        <a @click="v2.arantm_is_stop_transfusi = 'N'" href="javascript:;" class="btn btn-labeled btn-labeled-left btn-success">
                          <b><i class="fa-solid fa-hand"></i></b>
                          <span>Lanjutkan Transfusi</span>
                        </a>
                      </template>
                      -->

                      <a href="javascript:;" v-if="(v1.data||[]).length > 1" @click="deleteIntransfuse(v2)" class="btn btn-labeled btn-labeled-left btn-danger ml-2">
                      <b><i class="icon-bin"></i></b>
                        <span>Hapus Data</span>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr v-if="v1.type == 'I'">
                  <td class="table-info"></td>
                  <td class="table-info text-center">
                    <a href="javascript:;" @click="addIntransfuse(v)" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                  </td>
                </tr>
              </tbody>
              </div>

              <!---
              <tfoot>
                <tr>
                  <th colspan="2" class="text-center">
                    <a href="javascript:;" v-if="(dataMonitoring||[]).length > 1" @click="removeMonitoring" class="btn bg-danger btn-labeled btn-labeled-left mr-2">
                      <b><i class="icon-bin"></i></b>Hapus Monitoring</a>
                    <a href="javascript:;" @click="addMonitoring" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                      <b><i class="icon-plus2"></i></b>Tambah Monitoring</a>
                  </th>
                </tr>
              </tfoot>
              -->
            </table>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn mr-1" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="button" class="btn btn-success mr-1" @click="simpanSementara" data-dismiss="modal">Simpan Sementara</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
const moment = require('moment')


export default{
  extends: GlobalVue, 
  components: {
    Datepicker,VueTimepicker
  },
  data() {
    return {
      dataMonitoring: [],
      dataTransfusi: [],
      activeTab: 0,
      rowTransfusi: {}
    }
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    rowMerge(){
      return {
        ...this.rowTransfusi,
        ...this.isParent.rowEdit
      }
    },
    isReaksiTransfusi(){
      let reaksiTransfusi = 'N'
      for(let i = 0; i < (this.dataTransfusi||[]).length; i++){
        for(let j = 0; j < (this.dataTransfusi[i].dataMonitoring||[]).length; j++){
          for(let k = 0; k < (this.dataTransfusi[i].dataMonitoring[j]['data']||[]).length; k++){
            for(let l = 0; l < (this.dataTransfusi[i].dataMonitoring[j]['data'][k]['data']||[]).length; l++){
              if((this.dataTransfusi[i].dataMonitoring[j]['data'][k]['data'][l]['arantm_reaksi_transfusi']||[]).length){
                reaksiTransfusi = "Y"
              }
            }
          }
        }
      }
      return reaksiTransfusi
    },
  },
  methods: {
    changeGejala(e,k,k1,k2){
      let typeReaksi = null
      let berat = ["Hematuria / Hemoglobinuria**","Nyeri dada","Syok**","Sesak napas**"]
      let sedang = ["Demam","Takikardi","Nyeri kepala"]
      let ringan = ["Urtikaria","Gatal"]

      for(let i = 0; i < (berat||[]).length; i++){
        if(e.indexOf(berat[i]) != -1) typeReaksi = "Berat"
      }
      if(typeReaksi != "Berat"){
        for(let i = 0; i < (sedang||[]).length; i++){
          if(e.indexOf(sedang[i]) != -1) typeReaksi = "Sedang"
        }
        if(typeReaksi != "Sedang"){
          for(let i = 0; i < (ringan||[]).length; i++){
            if(e.indexOf(ringan[i]) != -1) typeReaksi = "Ringan"
          }
        }
      }

      if(this.dataMonitoring[k]['data'][k1]['data'][k2]['arantm_reaksi_type'] != typeReaksi){
        this.dataMonitoring[k]['data'][k1]['data'][k2]['arantm_reaksi_transfusi'] = []
      }

      this.dataMonitoring[k]['data'][k1]['data'][k2]['arantm_reaksi_type'] = typeReaksi
    },
    changeTab(tab){
      this.activeTab = tab
      this.dataMonitoring = this.dataTransfusi[tab]['dataMonitoring']  
      this.rowTransfusi = this.dataTransfusi[tab]
      
    
      $(".sign-container canvas").attr("width",249)
      $(".sign-container canvas").attr("height",160)               
      this.$nextTick(() => {
        if(this.$refs['ttdPenerima']){
          this.$refs['ttdPenerima'].fromDataURL(this.rowTransfusi.arantlt_penerima_ttd)
        } 
        if(this.$refs['ttdPetugas']){
          this.$refs['ttdPetugas'].fromDataURL(this.rowTransfusi.arantlt_petugas_ttd)
        } 
      })
    },
    
    simpanSementara(){

      let data = this.rowMerge
      data.type = 'submit-monitoring-transfusi-recent'
      data.arantl_is_reaksi_transfusi = this.isReaksiTransfusi
      data.ap_fullname = this.isParent.row.ap_fullname
      data.ap_code = this.isParent.row.ap_code

      this.isParent.loadingOverlay = true
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        this.isParent.loadingOverlay = false
        let resp = res.data
        resp.statusType = 200
        this.$swal({
          title: resp.message,
          icon: resp.status,
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(result => {
          this.apiGet()
          this.isParent.openModal = false
        })
      }).catch(err => {
        this.isParent.loadingOverlay = false
        if (err) {
            err.statusType = err.status
            err.status = "error"
            err.title = err.response?.data?.title
            err.message = err.response?.data?.message
            err.messageError = err.message
        }
        this.isParent.doSetAlertForm(err)
      })


    },

    autosaveTransfusi: _.debounce(function (data) {
      data.type = 'auto-save-transfusi'
      data.data = this.dataMonitoring
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data:data}, 
          "POST"
      )
    },1000),  
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data:data}, 
          "POST"
      )
    },1000),

    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.rowTransfusi[field] = null
      setTimeout(()=>{
        this.autoSave(this.rowMerge,true)
      },100)
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.rowTransfusi[field] = data
        setTimeout(()=>{
          this.autoSave(this.rowMerge,true)
        },100)
      }
    },
    getData(){
      this.isParent.loadingOverlay = true
      let data = {
        id : this.isParent.rowEdit.arantl_id      
      }
      data.type = 'get-monitoring'
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false

        this.dataTransfusi = resp.dataTransfusi
        this.dataMonitoring = this.dataTransfusi[0]['dataMonitoring']
        this.rowTransfusi = this.dataTransfusi[0]

        // if(!(this.dataMonitoring||[]).length){
        //   this.addMonitoring()
        // } 
    
        $(".sign-container canvas").attr("width",249)
        $(".sign-container canvas").attr("height",160)  
        setTimeout(()=>{            
          this.$nextTick(() => {
            if(this.$refs['ttdPenerima']){
              this.$refs['ttdPenerima'].fromDataURL(this.rowTransfusi.arantlt_penerima_ttd)
            } 
            if(this.$refs['ttdPetugas']){
              this.$refs['ttdPetugas'].fromDataURL(this.rowTransfusi.arantlt_petugas_ttd)
            } 
            console.log("set canvas sini") 
          })
        },1000)
      })
    },

    doSubmit(){
      this.$refs['VFormTransfusi'].validate().then(success => {  
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data Transfusi?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowMerge
              data.type = 'submit-monitoring-transfusi'
              data.arantl_is_reaksi_transfusi = this.isReaksiTransfusi
              data.ap_fullname = this.isParent.row.ap_fullname
              data.ap_code = this.isParent.row.ap_code

              this.isParent.loadingOverlay = true
              Gen.apiRest(
                  "/do/"+this.isParent.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                this.isParent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(result => {
                  this.isParent.openModal = false
                  this.isParent.apiGet()
                })
              }).catch(err => {
                this.isParent.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.isParent.doSetAlertForm(err)
              })
            }
          })
        }
      })

    },
    
    // addMonitoring(){
    //   let data = {
    //     id : this.isParent.rowEdit.arantl_id,
    //     regId: this.pageSlug,
    //     type: 'add-monitoring'
    //   }
    //   this.isParent.loadingOverlay = true
    //   Gen.apiRest(
    //       "/do/"+this.isParent.modulePage,
    //       {data: data}, 
    //       "POST"
    //   ).then(res=>{
    //     let resp = res.data
    //     this.isParent.loadingOverlay = false
    //     this.getData()
    //   })
      
    // },
    // removeMonitoring(){
    //   let data = {
    //     id : this.isParent.rowEdit.arantl_id,
    //     regId: this.pageSlug,
    //     groupId: this.dataMonitoring[(this.dataMonitoring||[]).length - 1]['group_id'],
    //     type: 'remove-monitoring'
    //   }
    //   this.isParent.loadingOverlay = true
    //   Gen.apiRest(
    //       "/do/"+this.isParent.modulePage,
    //       {data: data}, 
    //       "POST"
    //   ).then(res=>{
    //     let resp = res.data
    //     this.isParent.loadingOverlay = false
    //     this.getData()
    //   })
    // },
    addIntransfuse(row){
      let data = {
        id : this.isParent.rowEdit.arantl_id,
        regId: this.pageSlug,
        groupId: row.group_id,
        type: 'add-intransfuse',
        typeTransfuse: 'I'
      }
      this.isParent.loadingOverlay = true
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false
        this.getData()
      })
    },
    deleteIntransfuse(row){
      let data = {
        type: 'delete-intransfuse',
        id: row.arantm_id,
      }
      this.isParent.loadingOverlay = true
      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false
        this.getData()
      })
    }
  },
  mounted() {
    this.getData()
  },
  watch:{
    'rowMerge': {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    dataMonitoring: {
      handler(v) {
        this.autosaveTransfusi(v)
      },
      deep: true
    },
  }
}
</script>