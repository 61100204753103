<template>
  <div class="content-wrapper overflow-visible">
    
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <template v-if="isListDetail">
        <div class="page-title pt-0 pb-3">
          <router-link :to="{ name: 'RanapPasienPerawatan' }" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </router-link>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-white">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Tindakan Laboratorium</h5>
                  <div class="col-md-auto">
                      <div class="form-group mb-0">
                          <button @click="apiGet" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                      </div>
                  </div>
                  <a href="javascript:;" v-if="moduleRole('add')" @click="addTindakan()" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-plus2"></i></b>Buat Permintaan</a>
                </div>
              </div>
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama & No. RM</th>
                    <th>Diagnosa</th>
                    <th>Jenis Permintaan</th>
                    <th>Tindakan</th>
                    <th>Tanggal Tindakan</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (dataTindakanLab||[])" >
                    <tr :class="v.arantl_have_nilai_kritis_lab == 'Y'?'kritis-animate':''" :key="k">
                      <td>
                        <span>{{k+1}}</span>
                      </td>
                      <td>
                        <div class="d-flex flex-column align-items-start">
                          <a href="javascript:;" class="font-weight-semibold border-bottom">{{row.ap_fullname||"-"}}</a>
                          <small>{{row.ap_code}}</small>
                          <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                        </div>
                      </td>
                      <td>
                        {{v.arantl_diagnosa||"-"}}
                      </td>
                      <td>
                        <ul style="padding-left:10px;">
                          <li v-if="v.arantl_is_lab == 'Y'">Periksa Lab</li>
                          <li v-if="v.arantl_is_transfusi == 'Y'">Transfusi</li>
                          <li v-if="v.arantl_is_ph == 'Y'">Phlebotomy</li>
                          <li v-if="v.arantl_is_hiv == 'Y'">HIV</li>
                        </ul>
                      </td>
                      <td>
                        {{v.arantl_tindakan||"-"}}
                      </td>
                      <td>
                        <span v-if="v.arantl_submit_date">
                          {{v.arantl_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                        </span>
                        <span v-else> - </span>
            
                        <small class="font-weight-semibold" v-if="v.input_by"> dibuat oleh {{v.input_by}}</small>
                      </td>
                      <td>
                        <template v-if="v.arantl_is_draft == 'Y'">
                          <span class="badge bg-warning mb-1">Draft </span>
                        </template>
                        <template v-else>
                          <div v-if="v.arantl_is_lab == 'Y'" v-b-tooltip.hover title="Status Laboratorium">
                            <span v-if="v.arantl_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                            <span v-else-if="v.arantl_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                            <span v-else class="badge bg-success mb-1">Selesai </span>
                          </div>
                          <div v-if="v.arantl_is_transfusi == 'Y'" v-b-tooltip.hover title="Status Transfusi">
                            <span v-if="v.arantl_tr_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                            <span v-else-if="v.arantl_tr_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                            <span v-else class="badge bg-success mb-1">Selesai </span>
                          </div>
                          <div v-if="v.arantl_is_ph == 'Y'" v-b-tooltip.hover title="Status Phlebotomy">
                            <span v-if="v.arantl_ph_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                            <span v-else-if="v.arantl_ph_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                            <span v-else class="badge bg-success mb-1">Selesai </span>
                          </div>
                          <div v-if="v.arantl_is_hiv == 'Y'" v-b-tooltip.hover title="Status HIV">
                            <span v-if="v.arantl_hiv_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                            <span v-else-if="v.arantl_hiv_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                            <span v-else class="badge bg-success mb-1">Selesai </span>

                            <span v-if="v.arantl_hiv_approved == 'N' && v.arantl_hiv_status != 'WAITING'" class="badge bg-danger mb-1">Pemeriksaan HIV ditolak</span>
                            <span v-else-if="v.arantl_hiv_approved == 'Y' && v.arantl_hiv_status != 'WAITING'" class="badge bg-success mb-1">Pemeriksaan HIV disetujui </span>
                          </div>

                          <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status == 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan</span>
                          
                          <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status != 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan Sementara</span>

                        <span v-if="v.arantl_have_nilai_kritis_lab == 'Y'" class="badge bg-danger mb-1">Kritis </span>

                        </template>
                      </td>
                      <td>
                      

                        <a href="javascript:;" v-if="moduleRole('view')" class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" 
                        @click="toModal(v,'xl','Detail Tindakan Lab','DetailTindakanLab')"
                        v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

                        <a href="javascript:;" v-if="moduleRole('edit')" @click="toForm(v)" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>

                        <a href="javascript:;" v-if="v.arantl_is_draft != 'Y' && v.arantl_is_transfusi == 'Y' && v.arantl_tr_status != 'WAITING' && v.arantl_tr_status != 'DONE' && moduleRole('form_monitoring')" @click="toModal(v,'xl','Monitoring Tranfusi','FormMonitoringTransfusi')" class="btn rounded-round btn-sm btn-icon alpha-success border-success" v-b-tooltip.hover title="Monitoring Transfusi"><i class="fas fa-laptop-medical"></i></a>

                        
                        <a href="javascript:;" v-if="v.arantl_is_transfusi == 'Y' && v.arantl_tr_status != 'WAITING' && v.arantl_tr_status != 'DONE'" @click="changeStatusTransfusi(v)" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover :title="v.arantl_tr_is_hentikan != 'Y' ? 'Hentikan Transfusi Sementara':'Lanjutkan Transfusi'"><i class="fa-solid fa-cut"></i></a>

                        
                        <a href="javascript:;" v-if="v.arantl_is_transfusi == 'Y' && v.arantl_tr_status != 'WAITING' && v.arantl_tr_status != 'DONE'" @click="changeStatusTransfusiPermanent(v)" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover :title="'Hentikan Transfusi'"><i class="fa-solid fa-hand"></i></a>

                        <a href="javascript:;" v-if="moduleRole('inform_consent') && (v.arantl_is_transfusi == 'Y' || v.arantl_is_ph == 'Y')" @click="toModal(v,'xl','Dokumen Tindakan Lab','FormInfomConsent')" data-target="#UploadTtdTransfusi" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover title="View Inform Consent"><i class="fa-solid fa-file-signature"></i></a>

                        <a href="javascript:;" v-if="moduleRole('verif_kantong_darah') && v.arantl_ph_is_verif_lab == 'Y' && v.arantl_ph_status != 'DONE'" @click="toModal(v,'md','Verifikasi Penerimaan Kantong Darah','PemberianKantongDarah')" data-target="#PemberianKantongDarah" class="btn rounded-round btn-sm btn-icon alpha-success border-success" v-b-tooltip.hover title="Verifikasi Penerimaan Kantong Darah"><i class="fa-solid fa-square-check text-success-800"></i></a>

                        <a href="javascript:;" v-if="moduleRole('pengembalian_kantong_darah') && v.arantl_ph_is_verif == 'Y' && v.arantl_ph_is_verif_lab == 'Y' && v.arantl_ph_status != 'DONE'" @click="toModal(v,'md','Pengembalian Kantong Darah','PengembalianKantongDarah')"  data-target="#PengembalianKantongDarah" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Pengembalian Kantong Darah"><i class="fa-solid fa-arrow-right-arrow-left text-info-800"></i></a>

                        <a href="javascript:;" @click="toModal(v,'lg','Pemberian dan Pengembalian','RiwayatPengembalian')" v-if="moduleRole('pemberian_pengembalian_darah') && v.arantl_is_done_ph  == 'Y'" data-target="#PemberiandanPengembalian" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Riwayat Pemberian dan Pengembalian Darah"><i class="fa-solid fa-receipt text-info-800"></i></a>
                        
                        <a href="javascript:;" @click="toModal(v,'md','Persetujuan HIV','PersetujuanHIV')" v-if="moduleRole('persetujuan_hiv') && v.arantl_hiv_status == 'WAITING'" data-target="#PemberiandanPengembalian" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Persetujuan Tindakan HIV"><i class="fa-solid fas fa-book-medical"></i></a>
                        

                        <a href="javascript:;" @click="toModal(v,'sm','Upload Kajian HIV','UploadKajianHIV')" v-if="moduleRole('upload_kajian_hiv') && v.arantl_hiv_status != 'WAITING' && v.arantl_is_hiv == 'Y'" data-target="#PemberiandanPengembalian" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Upload Kajian HIV"><i class="icon-file-text2"></i></a>

                        <a href="javascript:;" v-if="v.arantl_is_draft == 'Y' && moduleRole('hapus')" @click="hapusTindakan(v)" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover title="Hapus Tindakan"><i class="icon-bin"></i></a>

                        <a href="javascript:;" v-if="v.arantl_have_nilai_kritis_lab == 'Y'" @click="changeStatusNilaiKritis(v)" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover :title="'Hentikan Kritis'"><i class="fa-solid fa-hand"></i></a>
                        
                      </td>
                    </tr>
                  </template>
                  <tr v-if="!(dataTindakanLab||[]).length">
                    <td colspan="99" class="text-center">Tidak ada Data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        >
          
          <!-- test -->
          <FormMonitoringTransfusi v-if="isModal == 'FormMonitoringTransfusi'" />     
          <FormInfomConsent v-if="isModal == 'FormInfomConsent'" />     
          <PemberianKantongDarah v-if="isModal == 'PemberianKantongDarah'"/> 
          <PengembalianKantongDarah v-if="isModal == 'PengembalianKantongDarah'" />
          <RiwayatPengembalian v-if="isModal == 'RiwayatPengembalian'" />
          <DetailTindakanLab v-if="isModal == 'DetailTindakanLab'" /> 
          <PersetujuanHIV v-if="isModal == 'PersetujuanHIV'" />
          <UploadKajianHIV v-if="isModal == 'UploadKajianHIV'" />
        </b-modal>
      </template>
      <template v-else>
        <Form /> 
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormMonitoringTransfusi from './FormMonitoringTransfusi.vue'
  import FormInfomConsent from './FormInfomConsent.vue'
  import PemberianKantongDarah from './PemberianKantongDarah.vue'
  import PengembalianKantongDarah from './PengembalianKantongDarah.vue'
  import RiwayatPengembalian from './RiwayatPengembalian.vue'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  import DetailTindakanLab from './DetailTindakanLab.vue'
  import PersetujuanHIV from './PersetujuanHIV.vue'
  import UploadKajianHIV from './UploadKajianHIV.vue'


  export default {
    extends: GlobalVue,
    components:{Form,FormMonitoringTransfusi,FormInfomConsent,PemberianKantongDarah,PengembalianKantongDarah,RiwayatPengembalian,PatientInfo,DetailTindakanLab,PersetujuanHIV,UploadKajianHIV},
    data() {
      return {
        rowReg: {},
        dataTindakanLab: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        
        mDPJP: [],
        patientData: {},
        loading: {
            patientInfo: false,
        }
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.labNo },    
    },
    methods: {
      changeStatusNilaiKritis(row){
        this.$swal({
          title: `Hasil kritis sudah dilaporkan?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText:  `Ya`,
          cancelButtonText:  `Tidak, kembali`,        
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.loadingOverlay=true
            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'change-status-nilai-kritis', id: row.arantl_id}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                  title: "Data Berhasil Disimpan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.apiGet()
              })
              .catch(err=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      changeStatusTransfusiPermanent(row){
        this.$swal({
          title: `Apakah Anda Yakin akan Menghentikan Tindakan Transfusi ?`,
          text: "Tindakan yang dipilih tidak dapat melakukan transfusi kembali",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText:  `Ya`,
          cancelButtonText:  `Tidak, kembali`,        
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.loadingOverlay=true
            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'change-status-transfusi-permanent', id: row.arantl_id}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                  title: "Data Berhasil Disimpan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.apiGet()
              })
              .catch(err=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      changeStatusTransfusi(row){
        let changeStatus = row.arantl_tr_is_hentikan == "Y" ? "N" : "Y"
        
        this.$swal({
          title: `Apakah Anda Yakin akan ${changeStatus=='Y' ? 'Menghentikan Sementara' : 'Melanjutkan'} Tindakan Transfusi ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText:  `Ya`,
          cancelButtonText:  `Tidak, kembali`,        
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.loadingOverlay=true
            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'change-status-transfusi', id: row.arantl_id, status:changeStatus}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                  title: "Data Berhasil Disimpan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.apiGet()
              })
              .catch(err=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
      hapusTindakan(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Tindakan Laboratorium ?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.arantl_id
            }
            data.type = 'hapus-tindakan'
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Tindakan Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet()
            })
          }
        })
      },
      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },

      addTindakan(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aranr_id
        }
        data.type = 'init-tindakan-lab'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, labNo: resp.arantl_id}})
            .catch(()=>{})
        })
      },
      toForm(row){
        this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, labNo: row.arantl_id}})
        .catch(()=>{})
      }
    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet()
      }
    }
  }

</script>