<template>
  <div>
    <validation-observer ref="VFormHIV">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-sm table-input">
              <thead>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Tindakan</h4>
                      <p>{{isParent.rowEdit.arantl_hiv_date | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Apakah pasien setuju dengan pemeriksaan HIV?</h4>
                      <b-form-radio-group
                        :options="Config.mr.yesNoOptV2"
                        v-model="isParent.rowEdit.arantl_hiv_approved"
                      />
                      <VValidate 
                        :name="'Penyetujuan Tindakan'" 
                        v-model="isParent.rowEdit.arantl_hiv_approved" 
                        :rules="{required: 1}"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Template Dokumen</h4>
                      <a class="btn_view" @click="downloadTemplate(isParent.rowEdit.arantl_hiv_approved)" data-popup="tooltip" title=""
                        data-original-title="Unduh Template Dokumen"><i
                          class="icon-file-download"></i></a>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Upload Dokumen<small class="text-danger">*</small></h4>
                      <Uploader v-model="isParent.rowEdit.arantl_hiv_file" isDocument type="docimage" />

                      <VValidate 
                        :name="'Dokumen Persetujuan HIV'" 
                        v-model="isParent.rowEdit.arantl_hiv_file" 
                        :rules="{required: 1}"
                      />
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn mr-1" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue, 
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  methods: {
    downloadTemplate(isApproved){
      let template
      if(isApproved == "Y"){
        template = "templateApprovedHIV"
      }else{
        template = "templateDisapprovedHIV"
      }

      let data = {exptype: 'xlsx', type: "export", id: this.isParent.rowEdit.arantl_id, pasienId: this.isParent.row.ap_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${template}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.isParent.loadingOverlay = false

              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Persetujuan-HIV-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.isParent.loadingOverlay = false

              alert('Not downloaded')
          }
      })
    },
    doSubmit(){
      this.$refs['VFormHIV'].validate().then(success => {  
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.type = 'submit-persetujuan-hiv'

              this.isParent.loadingOverlay = true
              Gen.apiRest(
                  "/do/"+this.isParent.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          })
        }
      })

    },
  },
}

</script>