<template>
  <div class="card">
    <validation-observer ref="VFormTindakLanjut">
    <b-form @submit.prevent="doSubmit">
      <div class="card-header bg-info">
        <h6 class="card-title font-weight-semibold">Laboratorium</h6>
      </div>
      <div class="card-body p-3">
        <div class="row g-2">
          <div class="col-md-4">
            <div>
              <label for="">Prioritas Pemeriksaan <strong class="text-danger">*</strong></label>
              <div>
                  <b-form-radio-group 
                  :options="Config.mr.configPrioritasPemeriksaan"
                  v-model="rowData['arantl_prioritas']" />
              </div>
              <VValidate :name="'Prioritas Pemeriksaan'"
                  v-model="rowData.arantl_prioritas"
                  :rules="toValidate(mrValidation.arantl_prioritas)" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-3">
        <div class="req-list-resume">
          <div class="row g-3">
          <template v-for="(v,k) in rowData.arantl_data">
              <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
              <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">
                      {{v.head||"-"}}</h6>
                  </div>
                  <div class="card-body py-2">
                  <template v-for="v1,k1 in (v.dubData||[])">
                      <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                      :key="k1+'labdatas'">
                      <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                      <template v-for="v2,k2 in (v1.data||[])">
                          <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                          <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                          <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                          <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                          </div>
                      </template>
                      </div>
                  </template>
                  </div>
              </div>
              </div>
          </template>
          <div class="col-md-4 col-lg-3" v-if="rowData.arantl_data_lainnya">
              <div class="card shadow-0 border">
              <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">Item
                  Pemeriksaan Lainnya</h6>
              </div>
              <div class="card-body py-2">
                  <div class="req-list">
                  <div>
                      <i
                      class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <b class="align-middle font-weight-semibold">{{rowData.arantl_data_lainnya||"-"}}</b>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
          </div>
          <div class="row g-3 mt-3" v-if="rowData.arantl_is_transfusi == 'Y'">
            <div class="col-lg-12">
                <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Transfusi</h6>
                </div>
                <div class="card-body py-2">

                  <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Indikasi Transfusi</h4>
                                <p>{{rowData.arantl_tr_indikasi_transfusi||"-"}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Golongan Darah</h4>
                                <p>{{rowData.arantl_tr_golongan_darah||"-"}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Jumlah Kantong</h4>
                                <p>{{rowData.arantl_tr_jumlah_kantong||"-"}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Jenis Darah yang Diperlukan</h4>
                                <p>{{rowData.arantl_tr_jenis_darah||"-"}}</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tanggal Diperlukan</h4>
                                <p>{{rowData.arantl_tr_tanggal | moment("DD MMMM YYYY")}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Inform Consent</h4>
                                <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arantl_tr_inform_consent_id','Tindakan Transfusi')" v-if="rowData.arantl_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                  <i class="icon-file-eye2"></i>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Pasien Pernah Transfusi</h4>
                                <p>{{getConfigDynamic(Config.mr.pernahTidakPernah,rowData['arantl_tr_pasien_transfusi'])||"-"}}</p>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                </div>
            </div>
          </div>
          
          <div class="row g-3 mt-3" v-if="rowData.arantl_is_ph == 'Y'">
            <div class="col-lg-12">
                <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                      <h6 class="card-title text-uppercase font-weight-semibold">Tindakan Phleubotomy</h6>
                  </div>
                  <div class="card-body py-2">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tanggal dilakukan</h4>
                                <p>{{rowData.arantl_ph_tanggal|moment('DD MMMM YYYY')}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Jumlah Kantong</h4>
                                <p>{{rowData.arantl_ph_jml_kantong||0}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Inform Consent</h4>
                                  <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arantl_ph_inform_consent_id','Tindakan Phlebotomy')" v-if="rowData.arantl_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                                    <i class="icon-file-eye2"></i>
                                  </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <div class="row g-3 mt-3" v-if="rowData.arantl_is_hiv == 'Y'">
          <div class="col-lg-12">
            <div class="card shadow-0 border">
              <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">Pemeriksaan HIV</h6>
              </div>
              <div class="card-body py-2">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal dilakukan</h4>
                            <p>{{rowData.arantl_hiv_date|moment('DD MMMM YYYY')}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>
        <div class="text-center mt-3">
          <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue mr-1"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
            Laboratorium</a>
          <a href="javascript:;" v-if="!isHideTransfusi" @click="openTransfusi=true" class="btn alpha-success mr-1"><i class="icon-plus2 mr-2"></i>Tindakan Transfusi</a>
          <a href="javascript:;" v-if="!isHidePH" @click="openPh=true" class="btn alpha-danger mr-1"><i class="icon-plus2 mr-2"></i>Tindakan
            Phlebotomy</a>
          <a href="javascript:;" v-if="!isHideHiv" @click="openHiv=true" class="btn alpha-success mr-1"><i class="icon-plus2 mr-2"></i>Pemeriksaan HIV
            </a>
        </div>
      </div>
      <div class="card-body p-3">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
                <label for="">Apakah Pasien Harus Berpuasa? <strong
                    class="text-danger">*</strong></label>
                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                v-model="rowData.arantl_fasting" />

                <VValidate :name="'Prioritas Pemeriksaan Lab'"
                v-model="rowData.arantl_fasting"
                :rules="toValidate(mrValidation.arantl_fasting)" />
            </div>
          </div>
          <div class="col-md-5">
            <div>
              <label for="">Catatan Permintaan</label>
              <b-form-textarea v-model="rowData.arantl_catatan_permintaan" name="CatatanPermintaan" id="CatatanPermintaan" rows="2"
                class="form-control"></b-form-textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
          <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </b-form>
    </validation-observer>

    
    <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
        <div class="modal-header d-block p-0 mt-3">
            <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
            <li v-for="(v,k) in rowData.arantl_data" :key="k" @click="changeTabLab(k)" class="nav-item">
                <a href="javascript:;" data-toggle="tab"
                :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
            </li>
            </ul>
        </div>
        <div class="modal-body p-0 mt-3">
            <div class="tab-content">
            <div v-for="(v,k) in rowData.arantl_data" :key="k+'labform'"
                :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
                <template v-for="(v1,k1) in (v.dubData||[])">
                <div :key="k1+'sss'">
                    <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                    {{v1.sub}}</div>
                    <div class="row sc-row g-0">
                    <template v-for="(v2,k2) in (v1.data||[])">
                        <div class="col-md-4 col-lg-3" v-if="v2.id" :key="k2+'xdx'">
                            <div class="selection-control">
                            <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                            </b-form-checkbox>
                            <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                            </div>
                        </div>
                    </template>
                    </div>
                </div>
                </template>
            </div>
            </div>
            <div class="row ml-1 mt-2">
            <div class="col-md-8 col-lg-4">
                <div class="form-group row">
                <label>Lainnya</label>
                <b-form-input v-model="rowData.arantl_data_lainnya" type="text" class="form-control" />
                </div>
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
            <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
            <span class="m-0" v-if="selectedLabInput">{{selectedLabInput||"-"}}</span>
            <span v-else> - </span>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white">
            <div class="text-right">
            <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
            </div>
        </div>
    </b-modal>

    <validation-observer ref="VFormTransfusi">
      <b-modal v-model="openTransfusi" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalTransfusi()" @ok.prevent="doSubmitTransfusi" :title="'Tindakan Tranfusi'" size="lg">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Pasien Pernah Transfusi <strong class="text-danger">*</strong></label>
              <b-form-radio-group
              :options="Config.mr.pernahTidakPernah"
              v-model="rowData['arantl_tr_pasien_transfusi']" />
            </div>
            <VValidate :name="'Pasien Pernah Transfusi'"
                v-model="rowData.arantl_tr_pasien_transfusi"
                :rules="toValidate(mrValidation.arantl_tr_pasien_transfusi)" />
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Indikasi Transfusi <strong class="text-danger">*</strong></label>
              <b-form-input v-model="rowData.arantl_tr_indikasi_transfusi" class="form-control" />
              <VValidate :name="'Indikasi Transfusi'"
                v-model="rowData.arantl_tr_indikasi_transfusi"
                :rules="toValidate(mrValidation.arantl_tr_indikasi_transfusi)" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label>Golongan Darah <strong class="text-danger">*</strong></label>
              <b-form-input v-model="rowData.arantl_tr_golongan_darah" class="form-control" />
              <VValidate :name="'Golongan Darah'"
                v-model="rowData.arantl_tr_golongan_darah"
                :rules="toValidate(mrValidation.arantl_tr_golongan_darah)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Jumlah Kantong <strong class="text-danger">*</strong></label>
              <b-form-input :disabled="rowData.arantl_is_draft == 'N'&&rowData.arantl_is_transfusi == 'Y'?true:false" :formatter="number" v-model="rowData.arantl_tr_jumlah_kantong" class="form-control" />
              <VValidate :name="'Jumlah Kantong'"
                v-model="rowData.arantl_tr_jumlah_kantong"
                :rules="toValidate(mrValidation.arantl_tr_jumlah_kantong)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Jenis Darah yang Diperlukan</label>
              <v-select placeholder="Jenis Darah" v-model="rowData.arantl_tr_jenis_darah"
                :options="Config.mr.JenisDarah" label="text" :clearable="true"
                :reduce="v=>v.value"></v-select>              
              <VValidate :name="'Jenis Darah yang Diperlukan'"
                v-model="rowData.arantl_tr_jenis_darah"
                :rules="toValidate(mrValidation.arantl_tr_jenis_darah)" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Tanggal Diperlukan</label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                </div>
                <datepicker input-class="form-control transparent"
                placeholder="Pilih Tanggal" class="my-datepicker"
                calendar-class="my-datepicker_calendar" v-model="rowData.arantl_tr_tanggal">
                </datepicker>
              </div>
              <VValidate :name="'Tanggal Diperlukan'"
                v-model="rowData.arantl_tr_tanggal"
                :rules="toValidate(mrValidation.arantl_tr_tanggal)" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="">Inform Consent</label>
              <div>
                <a href="javascript:;" @click="openInformConsentSingle(rowData,'arantl_tr_inform_consent_id','Tindakan Transfusi')" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo mr-1" v-b-tooltip.hover title="Tambah Informed Consent">
                  <i class="icon-file-check2"></i>
                </a>
                <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arantl_tr_inform_consent_id','Tindakan Transfusi')" v-if="rowData.arantl_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1" v-b-tooltip.hover title="Lihat Informed Consent">
                  <i class="icon-file-eye2"></i>
                </a>
                <a href="javascript:;" @click="deleteInformConsentSingle(rowData,'arantl_tr_inform_consent_id')" v-if="rowData.arantl_tr_inform_consent_id" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Consent">
                  <i class="icon-bin"></i>
                </a>
              </div>
                
              <VValidate :name="'Inform Consent Transfusi'"
                v-model="rowData.arantl_tr_inform_consent_id"
                :rules="{required: 1}" />
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>

    <validation-observer ref="VFormPH">
    <b-modal v-model="openPh" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalPH()" @ok.prevent="doSubmitPH" :title="'Tindakan Phlebotomy'" size="md">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Tanggal dilakukan</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
              </div>
              <datepicker input-class="form-control transparent"
              placeholder="Pilih Tanggal" class="my-datepicker"
              calendar-class="my-datepicker_calendar" v-model="rowData.arantl_ph_tanggal">
              </datepicker>
            </div>
            
            <VValidate :name="'Tanggal Dilakukan'"
              v-model="rowData.arantl_ph_tanggal"
              :rules="toValidate(mrValidation.arantl_ph_tanggal)" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Jumlah Kantong</label>
            <b-form-input :formatter="number" v-model="rowData.arantl_ph_jml_kantong" class="form-control" />
            <VValidate :name="'Jumlah Kantong Diperlukan'"
              v-model="rowData.arantl_ph_jml_kantong"
              :rules="toValidate(mrValidation.arantl_ph_jml_kantong)" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="">Inform Consent</label>
            <div>
              <a href="javascript:;" @click="openInformConsentSingle(rowData,'arantl_ph_inform_consent_id','Tindakan Phlebotomy')" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo mr-1" v-b-tooltip.hover title="Tambah Informed Consent">
                <i class="icon-file-check2"></i>
              </a>
              <a href="javascript:;" @click="openInformConsentDetailSingle(rowData,'arantl_ph_inform_consent_id','Tindakan Phlebotomy')" v-if="rowData.arantl_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1" v-b-tooltip.hover title="Lihat Informed Consent">
                <i class="icon-file-eye2"></i>
              </a>
              <a href="javascript:;" @click="deleteInformConsentSingle(rowData,'arantl_ph_inform_consent_id')" v-if="rowData.arantl_ph_inform_consent_id" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Consent">
                <i class="icon-bin"></i>
              </a>
            </div>

            <VValidate :name="'Inform Consent Phleubotomy'"
              v-model="rowData.arantl_ph_inform_consent_id"
              :rules="{required: 1}" />
          </div>
        </div>
      </div>
    </b-modal>
    </validation-observer>


    <validation-observer ref="VFormHIV">
    <b-modal v-model="openHiv" ok-title="Submit" cancel-title="Batalkan" @cancel.prevent="batalHIV()" @ok.prevent="doSubmitHIV" :title="'Tindakan HIV'" size="sm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Tanggal dilakukan</label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
              </div>
              <datepicker input-class="form-control transparent"
              placeholder="Pilih Tanggal" class="my-datepicker"
              calendar-class="my-datepicker_calendar" v-model="rowData.arantl_hiv_date">
              </datepicker>
            </div>
            
            <VValidate :name="'Tanggal Dilakukan'"
              v-model="rowData.arantl_hiv_date"
              :rules="toValidate(mrValidation.arantl_hiv_date)" />
          </div>
        </div>
      </div>
    </b-modal>
    </validation-observer>

    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcentSingle">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

  </div>
</template>



<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')
  import Datepicker from 'vuejs-datepicker'
 
  export default {
    extends: GlobalVue,
    components: {
      Datepicker
    },  
    data() {
      return {
        rowData: {},

        openLab: false,
        openTransfusi: false,
        openPh: false,
        activeTabLab: 0,
        rowIC: {
          aranic_is_pasien_setuju: null,
          aranic_persetujuan_tindakan: null
        },
        openModalIC: false,
        openModalICDetail: false,

        openHiv: false
      }
    },
    computed: {
      selectedLabInput() {
        let data = []
        for (let ik = 0; ik < (this.rowData.arantl_data || []).length; ik++) {
            for (let jk = 0; jk < (this.rowData.arantl_data[ik]['dubData'] || []).length; jk++) {
                for (let kk = 0; kk < (this.rowData.arantl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                    if (this.rowData.arantl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                        data.push(this.rowData.arantl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                }
            }
        }
        if (this.rowData.arantl_data_lainnya) {
            data.push(this.rowData.arantl_data_lainnya)
        }
        return data.join(", ")
      },
      now(){
        return moment().format('DD MMM YYYY, HH:mm:ss')
      },
      isHideTransfusi(){
        return this.rowData.arantl_is_transfusi == 'Y' && this.rowData.arantl_is_done_transfusi == 'Y'
      },
      isHidePH(){
        return this.rowData.arantl_is_ph == 'Y' && this.rowData.arantl_is_done_ph == 'Y'
      },
      isHideHiv(){
        return this.rowData.arantl_is_hiv == 'Y' && this.rowData.arantl_is_done_hiv == 'Y'
      }
    },
    methods: {
      
      back() {
        this.$router.back()
      },

      batalTransfusi(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan membatalkan Tindakan Transfusi?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.rowData.arantl_is_transfusi = 'N'
            this.rowData.arantl_tr_pasien_transfusi = null
            this.rowData.arantl_tr_indikasi_transfusi = null
            this.rowData.arantl_tr_golongan_darah = null
            this.rowData.arantl_tr_jumlah_kantong = null
            this.rowData.arantl_tr_jenis_darah = null
            this.rowData.arantl_tr_tanggal = null
            this.rowData.arantl_tr_inform_consent_id = null
            this.deleteInformConsentSingle(this.rowData,'arantl_tr_inform_consent_id')
            
            this.openTransfusi = false

            this.$swal({
              title: "Tindakan Berhasil Dibatalkan",
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          }
        })
      },
      batalPH(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan membatalkan Tindakan Phlebotomy?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.rowData.arantl_is_ph = 'N'
            this.rowData.arantl_ph_tanggal = null
            this.rowData.arantl_ph_jml_kantong = null
            this.rowData.arantl_ph_inform_consent_id = null

            this.deleteInformConsentSingle(this.rowData,'arantl_ph_inform_consent_id')

            this.openPh = false

            this.$swal({
              title: "Tindakan Berhasil Dibatalkan",
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          }
        })
      },
      batalHIV(){
        this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan membatalkan Tindakan HIV?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
        }).then(result => {
          if(result.value){
            this.rowData.arantl_is_hiv = 'N'
            this.rowData.arantl_hiv_date = null
            
            this.openHiv = false

            this.$swal({
              title: "Tindakan Berhasil Dibatalkan",
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          }
        })
      },
      doSubmitTransfusi(){
        this.$refs['VFormTransfusi'].validate().then(success=>{
          if(success){
             this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menambahkan Tindakan Transfusi?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if(result.value){
                this.rowData.arantl_is_transfusi = 'Y'
                this.$swal({
                  title: "Tindakan Berhasil Ditambahkan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.openTransfusi = false
              }
            })

          } 
        })
      },
      doSubmitPH(){
        this.$refs['VFormPH'].validate().then(success=>{
          if(success){
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menambahkan Tindakan Phlebotomy?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if(result.value){
                this.rowData.arantl_is_ph = 'Y'
                this.$swal({
                  title: "Tindakan Berhasil Ditambahkan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.openPh = false
              }
            })
          } 
        })
      },
      doSubmitHIV(){
        this.$refs['VFormHIV'].validate().then(success=>{
          if(success){
             this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menambahkan Tindakan HIV?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if(result.value){
                this.rowData.arantl_is_hiv = 'Y'
                this.$swal({
                  title: "Tindakan Berhasil Ditambahkan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.openHiv = false
              }
            })

          } 
        })
      },

      apiGetForm(params = {}, page = 1){
        if(!this.pageSlug){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.labNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.labNo : this.modulePage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })   
      },
      toValidate(val){
        return {...val}
      },

      isShowHeadLab(i) {
        let isData = 0
        for (let j = 0; j < (this.rowData.arantl_data[i]['dubData'] || []).length; j++) {
            for (let k = 0; k < (this.rowData.arantl_data[i]['dubData'][j]['data'] || []).length; k++) {
                if (this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']) {
                    isData += 1
                }
            }
        }
        return isData
      },
      isShowHeadSubHeadLab(i, j) {
        let isData = 0
        for (let k = 0; k < (this.rowData.arantl_data[i]['dubData'][j]['data'] || []).length; k++) {
            if (this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']) {
                isData += 1
            }
        }
        return isData
      },
      isShowLab(i, j, k) {
        let isData = 0
        if (this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
      },
      doOpenLab() {
        this.openLab = true
      },
      changeTabLab(e) {
        this.activeTabLab = e
      },
      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index]['text']
            }
        }
        return text
      },

      deleteInformConsentSingle(row,field){
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
              type: 'remove-ic-single',
              id: row[field],
          }
        }, 'POST').then(res => {
          this.rowData[field] = null
          this.autoSave(this.rowData)        
        })
      },
      
      openInformConsentDetailSingle(row,field,tindakan){
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
              type: 'get-data-ic-single',
              id: row[field],
          }
        }, 'POST').then(res => {
          this.rowIC = res.data.row 
          this.rowIC.tindakan = tindakan
          this.rowIC.id_tindakan = row[field]
          this.rowIC.field = field
        
          this.openModalICDetail = true                   
        })
      },
      openInformConsentSingle(row,field,tindakan){

          if(!row[field]){
          
              this.rowIC.aranic_is_pasien_setuju = null
              this.rowIC.aranic_persetujuan_tindakan = null

              this.rowIC.tindakan = tindakan
              this.rowIC.id_tindakan = row[field]
              this.rowIC.field = field


              this.rowIC.aranic_diagnosis = null
              this.rowIC.aranic_id = null
              this.rowIC.aranic_dasar_diagnosis = null
              this.rowIC.aranic_tindakan_dokter = null
              this.rowIC.aranic_indikasi_tindakan = null
              this.rowIC.aranic_tata_cara = null
              this.rowIC.aranic_tujuan = null
              this.rowIC.aranic_risiko = null
              this.rowIC.aranic_kompilasi = null
              this.rowIC.aranic_prognosis = null
              this.rowIC.aranic_alternatif = null
              this.rowIC.aranic_lainnya = null
    
          }else{  
              Gen.apiRest('/do/' + this.modulePage, {
                  data: {
                    type: 'get-data-ic-single',
                    id: row[field],
                  }
              }, 'POST').then(res => {
                  this.rowIC = res.data.row 
                  this.rowIC.tindakan = tindakan
                  this.rowIC.id_tindakan = row[field]  
                  this.rowIC.field = field
              })
              // console.log(edit)
          }
          this.openModalIC = true
      },
      submitInformConcentSingle(){
          this.$refs['VFormInformconsent'].validate().then(success => {
              if(success){
                  this.openModalIC = false

                  let data = this.rowIC
                  data.type = 'submit-data-inform-consent-single'
                  data.aranic_aranr_id = this.pageSlug
                  data.aranic_name = this.rowIC.tindakan
                  data.aranic_created_on = 'Laboratorium'

                  this.loadingOverlay = true
                  Gen.apiRest(
                  "/do/" + this.modulePage, {
                      data: data
                  },
                  "POST"
                  ).then(res => {
                      this.loadingOverlay = false
                      this.autoSave(this.rowData)

                      this.rowData[this.rowIC.field] = res.data

                      this.rowIC.aranic_id = null
                      this.rowIC.field = null

                      
                      Gen.apiRest(
                          "/do/"+this.modulePage,
                          {data:this.rowData}, 
                          "POST"
                      )
                      
                      return this.$swal({
                          title: "Data Informed Consent berhasil disimpan",
                          icon: 'success',
                          confirmButtonText: 'Ok'
                      })
                  })
              }
          })
      },

      autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        data.arantl_tindakan = this.selectedLabInput

        if(!data.isEdit){
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:data}, 
              "POST"
          )
        }
      },1000),

      doSubmit(){
        this.$refs['VFormTindakLanjut'].validate().then(success => {
          if (!success) {
            return this.$swal({
                icon: 'error',
                title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
                if (result.value) {
                    setTimeout(() => {
                        let inv = []
                        let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                        for (let i = 0; i < (el || []).length; i++) {
                            if (el[i].style.display !== 'none') {
                            inv.push(el[i].id)
                            }
                        }
                                    
                        if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        })
                    }, 500)
                }
            })
          }
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.rowData
                data.type = 'submit-data'
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + this.modulePage, {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                    })
                })
              }
            }).catch(err => {
              this.loadingOverlay = false
              if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
          }
          
        })
      }
    },
    mounted() {
      this.apiGetForm()
    },
    watch: {
      rowData: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      }
    }
  }
</script>