<template>
  <div>
    <validation-observer ref="VFormPemberianDarah">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-sm patient-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Laboratorium</th>
                  <th>Rawat Inap</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tanggal</td>
                  <td>{{isParent.rowEdit.arantl_ph_tanggal_lab | moment('DD MMM YYYY')}}</td>
                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                      </div>
                      <datepicker input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar" v-model="isParent.rowEdit.arantl_ph_tanggal">
                      </datepicker>
                    </div>
                    <VValidate :name="'Tanggal Dilakukan'"
                      v-model="isParent.rowEdit.arantl_ph_tanggal"
                      :rules="{required: 1}" /> 
                  </td>
                </tr>
                <tr>
                  <td>Jam</td>
                  <td>{{isParent.rowEdit.arantl_ph_waktu_lab}}</td>
                  <td>
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                      </div>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.rowEdit.arantl_ph_waktu">
                      </vue-timepicker>
                    </div>
                    <VValidate :name="'Waktu Dilakukan'"
                      v-model="isParent.rowEdit.arantl_ph_waktu"
                      :rules="{required: 1}" /> 
                  </td>
                </tr>
                <tr>
                  <td>Jumlah Kantong</td>
                  <td>{{isParent.rowEdit.arantl_ph_jml_kantong_lab||"-"}}</td>
                  <td>
                    <b-form-input v-model="isParent.rowEdit.arantl_ph_jml_kantong" :formatter="isParent.number" type="text" name="name" class="form-control" />
                    <VValidate :name="'Jumlah Kantong'"
                      v-model="isParent.rowEdit.arantl_ph_jml_kantong"
                      :rules="{required: 1}" />
                  </td>
                </tr>
                <tr>
                  <td>Petugas</td>
                  <td>{{isParent.rowEdit.arantl_ph_petugas_lab||"-"}}</td>
                  <td>
                    <b-form-input v-model="isParent.rowEdit.arantl_ph_petugas" type="text" name="name" class="form-control" />
                    <VValidate :name="'Petugas'"
                      v-model="isParent.rowEdit.arantl_ph_petugas"
                      :rules="{required: 1}" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  components: {
    Datepicker,VueTimepicker
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.isParent.rowEdit.arantl_ph_waktu = this.isParent.rowEdit.arantl_ph_waktu||moment().format('HH:mm')   
    this.isParent.rowEdit.arantl_ph_jml_kantong = this.isParent.rowEdit.arantl_ph_jml_kantong||this.isParent.rowEdit.arantl_ph_jml_kantong_lab
    this.isParent.rowEdit.arantl_ph_petugas = this.isParent.rowEdit.arantl_ph_petugas||this.isParent.user.fullName
  },
  methods: {
    doSubmit(){
      this.$refs['VFormPemberianDarah'].validate().then(success => {
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'submit-pemberian-darah'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
          }).catch(err => {
              this.isParent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.isParent.doSetAlertForm(err)
          })
        }
      })  
    },
  },
}
</script>