<template>
  <div>
    <validation-observer ref="VFormHIV">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <Uploader v-model="isParent.rowEdit.arantl_hiv_kajian_doc" isDocument type="docimage" />

            <VValidate 
              :name="'Dokumen Kajian'" 
              v-model="isParent.rowEdit.arantl_hiv_kajian_doc" 
              :rules="{required: 1}"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn mr-1" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue, 
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  methods: {
    doSubmit(){
      this.$refs['VFormHIV'].validate().then(success => { 
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.type = 'upload-kajian-hiv'

              this.isParent.loadingOverlay = true
              Gen.apiRest(
                  "/do/"+this.isParent.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          })
        } 
      })
    },
  },
}
</script>